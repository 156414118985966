import React, { useState, useEffect } from 'react';
import Page from './Page';
import ScorePage from './ScorePage';

// The shuffleArray function remains unchanged
const shuffleArray = (array) => {
  let curId = array.length;
  while (0 !== curId) {
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
};

const Main = () => {
  const [selectedImages, setSelectedImages] = useState([]); // This will store the images fetched from the API
  const [currentPage, setCurrentPage] = useState(0);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(true);
    fetch('https://backend.realorai.com/api/batchgetimagedata')
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data)) {
          const transformedData = data.map(img => ({
            id: img.id,
            image: img.url,
          }));
          console.log("Transformed Data:", transformedData)
          const shuffledImages = shuffleArray(transformedData).slice(0, 10);
          setSelectedImages(shuffledImages);
        }
      })
      .catch(err => {
        console.error("Failed to fetch image data:", err);
      })
      .finally(() => {
        setIsLoading(false);  // Set the loading status to false regardless of whether the API call succeeded or failed.
      });
  }, []);


  const handleNext = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }
    if (currentPage < selectedImages.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (currentPage >= selectedImages.length) {
    return (
      <ScorePage score={score} totalQuestions={selectedImages.length} />
    );
  }

  return (
    <Page
      imageData={selectedImages[currentPage]}
      page={currentPage + 1}
      totalPages={selectedImages.length}
      onNext={handleNext}
    />
  );
};

export default Main;
