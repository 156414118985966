import React from 'react';
import './ContactForm.css';

const ContactForm = () => (
    <div className="contact-form container mt-5">
        <h3 className="mb-4 text-center">Send us a message!</h3> {/* New heading */}
        
        <form action="https://formspree.io/f/myyqlgzo" method="POST">
            <div className="form-group row">
                <label htmlFor="subject" className="col-sm-2 col-form-label">Subject:</label>
                <div className="col-sm-10">
                    <input type="text" name="subject" required className="form-control input-field" id="subject" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="message" className="col-sm-2 col-form-label">Message:</label>
                <div className="col-sm-10">
                    <textarea name="message" required className="form-control input-field" id="message"></textarea>
                </div>
            </div>

            <button type="submit" className="btn btn-primary submit-button">Send</button>
        </form>
    </div>
);

export default ContactForm;
