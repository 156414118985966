/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */

import './ScorePage.css'; 
import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';

const handleGoToLanding = () => {
  window.location.reload();
};

const getFeedbackMessage = (scorePercentage) => {
  if (scorePercentage > 0.8) {
    return "Top-notch spotting!";
  } else if (scorePercentage >= 0.5) {
    return "Not too shabby!";
  } else {
    return "Keep Practicing!";
  }
};

const ScorePage = ({ score, totalQuestions }) => {
  useEffect(() => {
    // Track that the ScorePage has been viewed
    window.gtag('event', 'Viewed ScorePage', {
        'event_category': 'Score'
    });
  }, []);

  useEffect(() => {
    // Handler function for AddToAny's share event
    const handleShare = (event) => {
        if (event.type === 'addtoany_share') {
            window.gtag('event', 'Content Shared', {
                'event_category': 'Score',
                'event_label': event.data.service // this captures the name of the service (e.g., "Facebook", "WhatsApp")
            });
        }
    };

    // Attach the handler function to the AddToAny share event
    document.addEventListener('addtoany_share', handleShare);

    // Clean up the event listener when the component unmounts
    return () => {
        document.removeEventListener('addtoany_share', handleShare);
    };
}, []);


  const shareTitle = `🔍 Outsmarted the AI! Scored ${score} / ${totalQuestions}. Think you can beat me? #realorai`;
  const scorePercentage = score / totalQuestions;
const feedbackMessage = getFeedbackMessage(scorePercentage);
  const backgroundImageClass = score / totalQuestions > 0.5 
  ? "scorepage-background-high" 
  : "scorepage-background-low";
  return (
    <div className={`scorepage-background ${backgroundImageClass}`}>
      <Helmet>
        <script async src="https://static.addtoany.com/menu/page.js"></script>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div className="scorepage-content">
        <div className="score-display">
          Your Score: <span>{score} / {totalQuestions}</span>
          <div className="score-feedback">{feedbackMessage}</div>
        </div>

        <button
          className="btn btn-danger play-again-button"
          onClick={handleGoToLanding}
        >
          Play Again
        </button>

        <div className="a2a_kit a2a_kit_size_32 a2a_default_style"
          data-a2a-title={shareTitle}
          data-a2a-url={window.location.href}
        >
          <a className="a2a_dd" href="https://www.addtoany.com/share"></a>
          <a className="a2a_button_x"></a>
          <a className="a2a_button_whatsapp"></a>
          <a className="a2a_button_facebook"></a>
        </div>

        <div className="feedback-text">
          <p>Share to challenge a friend</p>
          <p>... and hint that some pictures might just be playing pretend!</p>
        </div>
      </div>
    </div>
  );
};

export default ScorePage;
